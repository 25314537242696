import React from "react";
import PhotoImg from "../photo1/photo1";
import Photo2 from "../photo2/photo2";
import Photo3 from "../photo3/photo3";
import Photo4 from "../photo4/photo4";

import {useTranslation} from "react-i18next";
import "./_style.scss";

function PhotoGallery() {
  const {t, i18n} = useTranslation('common');
  return (
    <div id="photos" className="photoGallery">
    <PhotoImg />
    <Photo2 />
    <Photo3/>
    <Photo4 />
    </div>
  );
}

export default PhotoGallery;
