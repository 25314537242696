import "./_style.scss";
import Logo from "../../img/LogoLaSantaBlancoTotal.png";
import {useTranslation} from "react-i18next";

function RedSection() {
  const {t, i18n} = useTranslation('common');
  return (
    <div className="redSection">
      <div className="move">
        <h1>{t('redSection.text1')}</h1>
        <div className="move2">
        <p className="subtitle fadeIn">
        {t('redSection.text2')}
          <br /> {t('redSection.text3')}
        </p>
        </div>
        <div className="move3">
        <p className="fadeIn">
          <br /> {t('redSection.text4')}{" "}
          <br /> {t('redSection.text5')}{" "}
        </p>
        <div className="logoRed">
        <img src={Logo} alt="logo" />
      </div>
      </div>
        </div>

    </div>
  );
}

export default RedSection;
