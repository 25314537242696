import "./_style.scss";
import Video from "../../video/tenemos_un_don.mp4";
import Logo from "../../img/LogoLaSantaBlanco.png";

function VideoSection() {
  return (
    <div className="videoSection">
      <div className="textContainer">
        <div className="floating1">
          <p>
            We have <br /> a Gift
          </p>
        </div>
        <div className="floating2">
          <p>We take the streets</p>
        </div>
      </div>
      
      <video className="video" autoplay='autoplay' muted controls='controls' loop='true'>
        <source src={Video} type="video/mp4" />
      </video>
    </div>
  );
}

export default VideoSection;
