import React from "react";
import Banner from "./components/banner/banner";
import "./App.less";
import "../src/css/_style.scss";
import { Layout, Button, Input } from "antd";
import RedSection from "./components/redSection/redSection";
import GreySection from "./components/greySection/greySection";
import VideoGallery from "./components/videoGallery/videoGallery";
import PhotoGallery from "./components/photoGallery/photoGallery";
import NavBar from "./components/navBar/navBar";
import Footer from "./components/Footer/footer";
import VideoSection from "./components/videoSection/videoSection";
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import common_en from "./translations/en/common-en.json";
import common_es from "./translations/en/common-es.json";

i18next.init({
  interpolation: { escapeValue: false },  
  lng: 'es',                              // language to use
        resources: {
            en: {
                common: common_en               // 'common' is our custom namespace
            },
            es: {
                common: common_es
            },
        },// React already does escaping
});

const { Content } = Layout;

const { Search } = Input;

function App() {

  return (
    <I18nextProvider i18n={i18next}>
    <Layout className="layout">
        <NavBar />
      <Content style={{ padding: "0 50px" }}>
        <div id="home" className="site-layout-content">
          <VideoSection />
        </div>
        <RedSection
        />
        <VideoGallery />
        <GreySection
        />
        <PhotoGallery/>
       <Footer/>
      </Content>
    </Layout>
    </I18nextProvider>
  );
}

export default App;
