import React, { useState } from "react";
import { Modal } from "antd";
import Video from "../../../video/sn_logo_santa.mp4";
import "./_style.scss";
import {useTranslation} from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
function ModalImg4() {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const {t, i18n} = useTranslation('common');
  return (
    <div className="img">
      <div onClick={showModal} className="text">
      <div>
      {t('videos.sannicolas')}
      </div>
      </div>
      <div>
      <FontAwesomeIcon icon={faPlayCircle} className="faicon"/>
      </div>
      <div className="Img4">
        {" "}
      </div>
      <Modal
        title={t('videos.sannicolas')}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        width={1000}
      >
        <video className="videoModal" autoPlay controls muted>
          <source src={Video} type="video/mp4" />
        </video>
      </Modal>
    </div>
  );
}

export default ModalImg4;