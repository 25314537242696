import "./_style.scss";
import { Carousel } from "antd";
import img1 from "../../img/video-1.png";
import img2 from "../../img/video-2.png";
import img3 from "../../img/video-3.png";

function onChange(a, b, c) {
  console.log(a, b, c);
}

function Banner () {
  return(
      <Carousel autoplay dots={true} dotPosition={"bottom"} afterChange={onChange}>
        <div id="fotos" className="container">
          <span>Titulo</span>
          <img src={img1} alt="video" />
        </div>
      </Carousel>
  );
}

export default Banner;
