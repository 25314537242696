import React from "react";
import ModalImg from "../atom/modal_1/modal";
import ModalImg2 from "../atom/modal_2/modal";
import ModalImg3 from "../atom/modal_3/modal";
import ModalImg4 from "../atom/modal_4/modal";
import ModalImg5 from "../atom/modal_5/modal";
import {useTranslation} from "react-i18next";
import "./_style.scss";

function VideoGallery() {
  const {t, i18n} = useTranslation('common');
  return (
    <div id="videos" className="videoGallery">
    <ModalImg />
    <ModalImg2 />
    <ModalImg3 />
    <ModalImg4 />
    <ModalImg5 />
    <div className="Img6"><span>We <br/> did <br/> it!</span></div>
    </div>
  );
}

export default VideoGallery;
