import "./_style.scss";
import { Layout } from "antd";
import Logo from "../../img/logo.svg";
import HamburgerMenu from "../hamburgerMenu/hamburgerMenu";
import {useTranslation} from "react-i18next";

const { Header } = Layout;

function NavBar() {
  const {t, i18n} = useTranslation('common');
  return (
    <Header>
      <div className="logo">
        <img src={Logo} alt="logo" />
      </div>
      <div className="Nav">
        <div className="hoverContainer">
          <a href="#home">{t('navBar.home')}</a>
          <div className="dotHover"></div>
        </div>

        <div className="hoverContainer">
          <a href="#nosotros">{t('navBar.nosotros')}</a>
          <div className="dotHover"></div>
        </div>

        <div className="hoverContainer">
          <a href="#videos">{t('navBar.videos')}</a>
          <div className="dotHover"></div>
        </div>

        <div className="hoverContainer">
          <a href="#fotos">{t('navBar.fotos')}</a>
          <div className="dotHover"></div>
        </div>

        <div className="Container">
          <div className="dotHover"></div>
        </div>

        <div className="hoverContainer">
          <a onClick={() => i18n.changeLanguage('en')}>EN</a>
          <div className="dotHover"></div>
        </div>
        <div className='separatorLanguaje'><p>|</p></div>
        <div className="hoverContainer">
        <a onClick={() => i18n.changeLanguage('es')}>ES</a>
          <div className="dotHover"></div>
        </div>
      </div>
      <div className="NavResponsive">
        <HamburgerMenu />
      </div>
    </Header>
  );
}

export default NavBar;
