import React, { useState } from "react";
import { Carousel } from "antd";
import img from "../../img/MM1.jpg";
import img2 from "../../img/MM2.jpg";
import img3 from "../../img/MM3.jpg";
import img4 from "../../img/MM4.jpg";
import img5 from "../../img/MM5.jpg";
import { Modal } from "antd";
import "./_style.scss";
import {useTranslation} from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera  } from '@fortawesome/free-solid-svg-icons'

function Photo4({ img1 }) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  function onChange(a, b, c) {
    console.log(a, b, c);
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const {t, i18n} = useTranslation('common');
  return (
    
    <div className="imgPhoto">
      <div onClick={showModal} className="text">
      <div>
      {t('fotos.minujin')}
      </div>
      </div>
      <div>
      <FontAwesomeIcon icon={faCamera } className="faicon"/>
      </div>
      <div className="Photo4"> </div>
      <Modal
       centered
        title={t('videos.panamericanos')}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
      <Carousel autoplay dots={true} dotPosition={"bottom"} afterChange={onChange}>
          <img src={img} alt="video" />
          <img src={img2} alt="video" />
          <img src={img3} alt="video" />
          <img src={img4} alt="video" />
          <img src={img5} alt="video" />
      </Carousel>
      </Modal>
    </div>
  );
}

export default Photo4;
