import "../../css/_style.scss";
import Logo2 from "../../img/LogoLaSantaBlanco.png";
import wp from "../../img/wpp-01.svg";
import insta from "../../img/insta-01.svg";

import {useTranslation} from "react-i18next";
function GreySection({ text1, text2, text3, text4 }) {
  const {t, i18n} = useTranslation('common');
  return (
    <div className="footer">
    <div className="top">
      <div className="footerLogo">
        <div>
          <img className="footerLogoHeight" src={Logo2} alt="logo" />
        </div>
        <div className="map">
          <a href="#home">{t('footer.home')}</a>
          <a href="#nosotros">{t('footer.nosotros')}</a>
          <a href="#videos">{t('footer.videos')}</a>
          <a href="#fotos">{t('footer.fotos')}</a>
        </div>
      </div>
      <div className="contact">
        <div>
          <p>{t('footer.contacto')}</p>
          <div>
          <a target="_blank" href="https://wa.me/5491168542042"><img src={wp} alt="whatsap" className='wpp'/></a>
          <a target="_blank" href="https://instagram.com/lasantaproductora?r=nametag"><img className='wpp' src={insta} alt="whatsap" /></a>
          </div>
        </div>
      </div>
    </div>
    <div className="bottom">
      <p>{t('footer.copy')}</p>
    </div>
  </div>
  );
}

export default GreySection;
