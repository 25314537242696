import "./_style.scss";
import { Popover, Button } from "antd";
import menu from "../../img/square.svg";
import {useTranslation} from "react-i18next";

function HamburgerMenu() {
  const {t, i18n} = useTranslation('common');

  const content = (
    <div className="Nav2">
      <div className="hoverContainer">
        <a href="#home">{t('navBar.home')}</a>
        <div className="dotHover"></div>
      </div>
  
      <div className="hoverContainer">
        <a href="#nosotros">{t('navBar.nosotros')}</a>
        <div className="dotHover"></div>
      </div>
  
      <div className="hoverContainer">
        <a href="#videos">{t('navBar.videos')}</a>
        <div className="dotHover"></div>
      </div>
  
      <div className="hoverContainer">
        <a href="#fotos">{t('navBar.fotos')}</a>
        <div className="dotHover"></div>
      </div>
  
      <div className="Container">
        <div className="dotHover"></div>
      </div>
  
      <div className="hoverContainer">
        <a href="#" onClick={() => i18n.changeLanguage('en')}>EN</a>
        <div className="dotHover"></div>
      </div>
  
      <div className="hoverContainer">
        <a href="#" onClick={() => i18n.changeLanguage('es')}>ES</a>
        <div className="dotHover"></div>
      </div>
    </div>
  );
  
  return (
    <div className="hamburgerMenu">
      <Popover content={content} trigger="click">
        <Button><img className="hamburger" src={menu} /></Button>
      </Popover>
    </div>
  );
}

export default HamburgerMenu;
