import "./_style.scss";
import {useTranslation} from "react-i18next";
function GreySection({ text1, text2, text3, text4 }) {
  const {t, i18n} = useTranslation('common');
  return (
    <div id="nosotros" className="GreySection">
      <div className="column moveUp">
        <h1>{t('greySection.text1')}</h1>
        <div className="moveUp2">
        <p className="subtitle">{t('greySection.text2')}</p>
        </div>
      <div>
        <p className="fadeIn2">{t('greySection.text3')}</p>
        </div>
      </div>
    </div>
  );
}

export default GreySection;
